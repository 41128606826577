<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <h5 class="titulo-item">{{ $t("shared.text_2066") }}</h5>

        <b-col cols="12">
          <div class="product-main">
            <div class="img-product">
              <img
                v-if="
                  !loading &&
                  sale &&
                  sale.product &&
                  sale.product.images &&
                  sale.product.images.length
                "
                :src="sale.product.images[0].path"
              />
            </div>
            <div class="name-product" v-if="!loading && sale.product">
              <span>{{ $t("shared.text_2023") }} #{{ sale.product.id }}</span>
              <template v-if="!sale.proposal">
                <p v-if="sale.product && sale.product.name">{{ sale.product.name }}</p>
                <p v-else>{{ sale.invoices[0].product.name }}</p>
                <p class="hash" v-if="sale.offer">
                  {{ $t("shared.text_2067") }} {{ sale.offer.hash }}
                </p>
                <p
                  class="hash"
                  v-if="sale.invoices && sale.invoices[0] && sale.invoices[0].offer.hash"
                >
                  {{ $t("shared.text_2067") }} {{ sale.invoices[0].offer.hash }}
                </p>
              </template>
              <template v-else>
                <p v-if="sale.proposal && sale.proposal.name">
                  {{ sale.proposal.name }}
                </p>
                <p class="hash" v-if="sale.offer">
                  {{ $t("shared.text_2067") }} {{ sale.offer.hash }}
                </p>
              </template>
            </div>
          </div>
        </b-col>
      </b-col>
    </b-row>

    <b-row class="item-dados">
      <b-col class="sumary">
        <b-row class="mb-3">
          <!-- Code -->
          <b-col cols="6">
            <p class="label-campo">{{ $t("shared.text_1996") }}</p>
            <p class="dados-campo">{{ sale.id }}</p>
          </b-col>
          <!-- Status -->
          <b-col cols="6">
            <p class="label-campo">{{ $t("shared.text_1997") }}</p>
            <template>
              <p class="dados-campo" :class="getStatus(sale.status).class">
                {{ getStatus(sale.status).text }}
              </p>
            </template>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <!-- Payment at -->
          <b-col v-if="sale.status == 'paid' || sale.status == 'trialing'" cols="6">
            <p class="label-campo">{{ $t("shared.text_2051") }}</p>
            <p class="dados-campo">{{ sale.paid_at | date }}</p>
          </b-col>
          <!-- Available at -->
          <b-col v-if="sale.status == 'paid' || sale.status == 'trialing'" cols="6">
            <p class="label-campo">{{ $t("shared.text_2436") }}</p>
            <p class="dados-campo">{{ sale.created_at | date }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Seller -->
    <b-row class="item-dados" v-if="sale.seller">
      <b-col cols="12" class="mb-3">
        <h5 class="titulo-item" v-if="sale.seller && sale.seller.name">
          {{ $t("shared.text_2159") }}
        </h5>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.seller.name">
          {{ $t("shared.text_2073") }}
        </p>
        <p class="dados-campo" v-if="sale.seller.name">
          <span>{{ sale.seller.name }}</span>
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.seller.email">
          {{ $t("shared.text_2074") }}
        </p>
        <p class="dados-campo" v-if="sale.seller.email">
          <span>{{ sale.seller.email }}</span>
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.seller.cellphone || sale.cellphone">
          {{ $t("shared.text_2076") }}
        </p>
        <p class="dados-campo" v-if="sale.seller.cellphone || sale.cellphone">
          <span v-if="/\#/.test(sale.seller ? sale.seller.cellphone : sale.cellphone)">
            {{ sale.seller ? sale.seller.cellphone : sale.cellphone }}
          </span>

          <a
            v-else
            :href="formatTel(sale.seller ? sale.seller.cellphone : sale.cellphone)"
            target="_blank"
            class="whats-sellere"
            >{{ sale.seller.cellphone }} <font-awesome-icon :icon="['fab', 'whatsapp']"
          /></a>
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p
          class="label-campo"
          v-if="sale.seller.document_type && (sale.seller.document || sale.seller.cpf)"
        >
          {{ sale.seller.document_type ? sale.seller.document_type : "Documento" }}
        </p>
        <p
          class="dados-campo"
          v-if="sale.seller.document_type && (sale.seller.document || sale.seller.cpf)"
        >
          <span>{{ sale.seller.document }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t("shared.text_2078") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.zipcode }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t("shared.text_2079") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.street }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t("shared.text_2080") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.number }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.seller.complement">
          {{ $t("shared.text_2081") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.complement }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t("shared.text_2082") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.neighborhood }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t("shared.text_2083") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.city }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.seller && sale.seller.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t("shared.text_2084") }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.seller.uf }}</span>
        </p>
      </b-col>
    </b-row>

    <!-- Payment details -->
    <b-row class="item-dados">
      <b-row>
        <h5 class="titulo-item">{{ $t("shared.text_2160") }}</h5>
        <b-col cols="12" class="pb-3">
          <p class="dados-campo">
            <!-- National/International -->
            <b-badge
              variant="secondary"
              v-if="
                (sale.country_code && sale.country_code == 'BR') || !sale.country_code
              "
            >
              <font-awesome-icon
                :icon="getIconMethod(sale.method, sale.base_currency_id)"
                class="icon-blue"
                :id="`method-icon-${sale.id}`"
              />
              {{ $t("shared.text_2061") }}
            </b-badge>
            <b-badge
              variant="secondary"
              v-if="sale.country_code && sale.country_code != 'BR'"
            >
              <div class="float-left mr-1">
                <img src="@/assets/img/icons/international-blue.svg" />
              </div>
              {{ $t("shared.text_2062") }}
            </b-badge>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <!-- Fees -->
        <b-col
          v-if="
            sale.product &&
            sale.product.installments > 1 &&
            sale.product.no_interest_installments != null
          "
          cols="12"
          class="mt-2"
        >
          <p class="label-campo">{{ $t("shared.text_2437") }}</p>
          <p class="dados-campo mt-2">
            <b-badge variant="primary"
              >{{
                sale.product.no_interest_installments
                  ? $t("shared.text_2063")
                  : $t("shared.text_2064")
              }}{{ $t("shared.text_2065") }}</b-badge
            >
          </p>
        </b-col>
      </b-row>
      <b-row v-show="interAccess">
        <!-- Local Currency -->
        <b-col v-if="sale.local_currency != undefined" cols="12" class="mt-2">
          <p class="label-campo mr-2">{{ $t("shared.text_2143") }}</p>
          <span v-if="sale.local_currency.name">{{ sale.local_currency.name }}</span>
        </b-col>
        <!-- Base Currency -->
        <b-col v-if="sale.base_currency != undefined" cols="12" class="mt-4">
          <p class="mr-2 label-campo">{{ $t("shared.text_2144") }}</p>
          <section class="d-flex align-items-center">
            <div class="chip" :style="setColors(sale.base_currency.currency)">
              {{ sale.base_currency.currency_symbol }}
            </div>
            <span> {{ sale.base_currency.currency }}</span>
          </section>
        </b-col>
        <!-- Country -->
        <b-col v-if="sale.country != undefined" cols="12" class="mt-4">
          <p class="mr-2 label-campo">{{ $t("shared.text_2150") }}</p>
          <section class="d-flex align-items-center">
            <img
              class="mr-2"
              style="height: 30px"
              :src="require(`@/assets/locales/flag-${sale.country.country_code}.svg`)"
            />
            <span>
              {{ sale.country && sale.country.name ? sale.country.name : "--" }}</span
            >
          </section>
        </b-col>
      </b-row>
      <b-row>
        <!-- Date -->
        <b-col cols="6" class="mb-3" v-if="sale.paid_at">
          <p class="label-campo">{{ $t("shared.text_2133") }}</p>
          <p class="dados-campo">
            {{ sale.paid_at | date }}
          </p>
        </b-col>
        <!-- Time -->
        <b-col cols="6" class="mb-3" v-if="sale.paid_at">
          <p class="label-campo">{{ $t("shared.text_2134") }}</p>
          <p class="dados-campo">
            {{ getHour(sale.paid_at) }}
          </p>
        </b-col>
        <!-- Currency -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t("shared.text_2135") }}</p>
          <p class="dados-campo">
            {{
              sale.local_currency && sale.local_currency.name
                ? sale.local_currency.name
                : "--"
            }}
          </p>
        </b-col>
        <!-- Country -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t("shared.text_2136") }}</p>
          <p class="dados-campo">
            {{
              sale.international_country && sale.international_country.name
                ? sale.international_country.name
                : sale.country && sale.country.name
                ? sale.country.name
                : "--"
            }}
          </p>
        </b-col>
        <!-- Comission -->
        <b-row class="my-3">
            <b-col cols="12">
                <p class="label-campo">{{ $t("shared.text_2161") }}</p>
            </b-col>
            <b-col cols="6">
                <p class="dados-campo">{{ $t("shared.text_2160") }}</p>
            </b-col>
            <b-col cols="6">
                <p v-if="sale.comission" class="dados-campo text-blue text-right">
                    <template v-if="sale.base_currency">
                        {{ sale.base_currency.currency_symbol }}
                    </template>
                    {{ formatMoney(+sale.comission) }}
                </p>
                <p v-else class="dados-campo text-blue text-right">
                    <template v-if="sale.base_currency">
                        {{ sale.base_currency.currency_symbol }}
                    </template>
                    0,00
                </p>
            </b-col>
        </b-row>
      </b-row>
    </b-row>
  </div>
</template>
<script>
import Money from "@/mixins/money";
import Utils from "@/mixins/utils";
import moment from "moment-timezone";
import errorPayment from "@/utils/errorPayment.js";
import { EventBus } from "@/main.js";
import { iconMethod } from "@/helpers.js";
import { textMethod } from "@/helpers.js";
import { symbolCurrency } from "@/helpers.js";

export default {
  name: "DetalhesComissao",
  mixins: [Utils,Money],
  props: {
    loading: {
      type: Boolean,
      default: () => true,
    },
    sale_id: {
      default: () => -1,
    },
    sale: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collapse: false,
      interAccess: false,
    };
  },
  created() {
    let userInterIds = process.env.USER_INTER.substring(
      1,
      process.env.USER_INTER.length - 1
    ).split(",");
    this.interAccess = userInterIds.some(
      (userId) => parseInt(userId) === this.userID
    );
  },
  methods: {
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id);
      }
      return "R$";
    },
    checkError(error) {
      return errorPayment(error);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(/[() -]/g, "")}`;
        }
      }
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: this.$t("shared.text_2114"), class: "icon-blue" };
            break;
          }
          case "authorized": {
            r = { text: this.$t("shared.text_2115"), class: "icon-blue" };
            break;
          }
          case "pending_approbation": {
            r = { text: this.$t("shared.text_2116"), class: "icon-blue" };
            break;
          }
          case "paid": {
            r = {
              text: this.$t("shared.text_2117"),
              class: "icon-green",
            };
            break;
          }
          case "refunded": {
            r = { text: this.$t("shared.text_2118"), class: "icon-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: this.$t("shared.text_2119"), class: "icon-warning" };
            break;
          }
          case "pending_refund":
          case "refund_pending": {
            r = { text: this.$t("shared.text_2120"), class: "icon-warning" };
            break;
          }
          case "refused": {
            r = { text: this.$t("shared.text_2121"), class: "icon-red" };
            break;
          }
          case "chargedback": {
            r = { text: this.$t("shared.text_2122"), class: "icon-warning" };
            break;
          }
          case "delayed": {
            r = { text: this.$t("shared.text_2123"), class: "icon-green" };
            break;
          }
          default: {
            r = { text: status, class: "icon-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "icon-black" };
    },
    setColors(coin) {
      if (coin === "BRL") return "color: rgba(0, 132, 92, 1);";
      if (coin === "USD") return "color: rgba(255, 203, 55, 1);";
      if (coin === "EUR") return "color: rgb(40 30 176);";
      if (coin === "GBP") return "color: rgba(0, 0, 0, 1);";
      return "color: #21a56e;";
    },
    getIconMethod(method, currency_id) {
      return iconMethod(method, currency_id);
    },
    getDate(datetime) {
      return moment.tz(datetime, "America/Sao_Paulo").format("DD/MM/YYYY HH:mm");
    },
    getHour(datetime) {
      return moment.tz(datetime, "America/Sao_Paulo").format("HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  .sumary {
    font-size: 13px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.center {
  text-align: center;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
.detalhe-item:first-child img {
  margin-right: 23px;
}
.detalhe-item:nth-child(2) img {
  margin-right: 28px;
}
.detalhe-item:nth-child(3) img {
  margin-right: 20px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.subtitle-item {
  font-size: 14px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.desctitle-item {
  font-size: 14px;
  color: #333;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 5px;
}

.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button,
.btn-flex button {
  width: 70%;
  height: 55px;
}
.whats-cliente {
  font-size: 14px;
  color: #2a63ab;
}
.whats-cliente svg {
  margin-left: 5px;
}

// metas

.meta-item + .meta-item {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
  padding: 10px 0;
}

.meta-item .key-meta {
  font-weight: 600;
  margin-bottom: 5px;
}
.meta-item .key-value {
  font-weight: 500;
  margin-left: 10px;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}

.decoration-none {
  text-decoration: none;
}

.btn-link-contract {
  display: flex;
  align-items: center;
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px !important;
  border-radius: 5px;
  height: 45px;
}

.btn-link-contract img {
  margin-right: 10px;
}
.product-avaliacao {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 7px;
  font-size: 14px;
}

.product-avaliacao + .product-avaliacao {
  margin-top: 25px;
}
.product-avaliacao.text-avaliacao {
  font-weight: normal;
  align-items: flex-start;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product .hash {
  font-size: 12px;
  color: #81858e;
  font-weight: inherit;
}
.name-product p {
  font-weight: 600;
}
.nota-status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.badge-primary,
.badge-secondary {
  padding: 5px 8px;
  font-weight: 600;
  background-color: rgba(255, 185, 55, 0.2);
  color: #ffb937;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
  font-size: 12px;
}
.badge-secondary {
  background: #ededf0;
  color: #333;
  padding: 10px;

  font-size: 13px;
}

// frete

.frete-item img {
  max-width: 80px;
  margin-bottom: 15px;
}

.frete-item .infos-frete {
  display: block;
  font-size: 15px;
  margin-bottom: 7px;
}

.frete-item .info-envio,
.frete-item .info-envio span {
  display: block;
  margin-bottom: 7px;
}

.info-envio .frete-valor {
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  .frete-item .infos-frete {
    width: 100%;
  }

  .frete-item .info-envio {
    grid-template-columns: 1fr;
  }

  .info-envio span {
    font-size: 13px;
  }

  .info-envio span + span {
    margin-top: 3px;
  }
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

.collapse.show {
  width: 100%;
}

.grid-participations {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.grid-participations + .grid-participations {
  margin-top: 7px;
}
.chip {
  width: 30px;
  height: 30px;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.text-blue {
    color: #2133D2;
}
</style>
