import Rest from '@/services/Rest';

/**
 * @typedef {AmbassadorService}
 */
export default class AmbassadorService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/ambassador'
}
