<template>
  <BaseModal
    name="comissions-export"
    size="lg"
    :title="$t('seller.sales.text_2613')"
    @shown="openModal"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>{{ $t('seller.sales.text_2614') }}</p>
          </div>
        </div>
        <b-form novalidate>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.sales.text_2615')"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('dados')"
                  class="btn-todos"
                  >{{ $t('seller.sales.text_2616') }}</a
                >
                <multiselect
                  v-model="dados"
                  :placeholder="$t('seller.sales.text_2617')"
                  label="name"
                  track-by="id"
                  :options="dice_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔︝"
                  ><span slot="noResult"
                    >{{ $t('seller.sales.text_2620') }}</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2622')">
                <b-form-input
                  v-model="email"
                  :placeholder="$t('seller.sales.text_2623')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.sales.text_2624') }}
      </BaseButton>
      <BaseButton variant="black" @click="onSubmit" :disabled="loading">
        {{ $t('seller.sales.text_2625') }}
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import AmbassadorService from "@/services/resources/AmbassadorService";

const serviceAmbassador = AmbassadorService.build();

export default {
  props: ["filters"],
  components: {
    Multiselect,
  },
  data() {
    return {
      email: "",
      loading: false,
      dados: [],
      dice_list: [
          { id: "product_name", name: "Nome do Produto" },
          { id: "indicated_name", name: "Nome do Indicado" },
          { id: "indicated_email", name: "E-mail do Indicado" },
          { id: "id", name: "Código da Venda" },
          { id: "date", name: "Data" },
          { id: "ambassador_balance", name: "Valor da Comissão" },
          { id: "method", name: "Metodo de Pagamento" },
          { id: "status", name: "Status da Comissão" },
          { id: "pay_day", name: "Data de pagamento" },
      ],
    };
  },
  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "dados": {
          this.dados = [
            { id: "product_name", name: "Nome do Produto" },
            { id: "indicated_name", name: "Nome do Indicado" },
            { id: "indicated_email", name: "E-mail do Indicado" },
            { id: "id", name: "Código da Venda" },
            { id: "date", name: "Data" },
            { id: "ambassador_balance", name: "Valor da Comissão" },
            { id: "method", name: "Metodo de Pagamento" },
            { id: "status", name: "Status da Comissão" },
            { id: "pay_day", name: "Data de pagamento" },
          ];
          break;
        }
      }
    },
    onSubmit() {
      this.loading = true;

      let basic = [];
      let dados = this.dados;
      for (let i = 0; i < dados.length; i++) {
        basic.push(dados[i].id);
      }

      let data = {
        id: "/export",
        basic: basic,
        email: this.email,
        filters: JSON.stringify(this.filters),
      };

      serviceAmbassador
        .createId(data)
        .then(() => {
          this.$bvToast.toast(
            this.$t('seller.sales.text_2626'),
            {
              title: this.$t('seller.sales.text_2613'),
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide("comissions-export");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(this.$t('seller.sales.text_2831'), {
            title: this.$t('seller.sales.text_2613'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("comissions-export");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal() {
      this.dados = [];
      this.email = this.$store.getters.getProfile.email;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
